import { get } from "lodash"
import ls from "local-storage"
import { Link, navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import { Div, Anchor, Image, Text } from "atomize"
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client"

import CTALink from "../CTALink"
import {
  TOGGLE_CART,
  FETCH_CUSTOMER,
  CHECKOUT_BY_ID,
  CART_VISIBILITY,
  TOGGLE_LOGIN_DRAWER,
  TOGGLE_REGISTER_DRAWER,
  LOGIN_DRAWER_VISIBILITY,
  REGISTER_DRAWER_VISIBILITY,
  FORGOT_PASSWORD_DRAWER_VISIBILITY,
  TOGGLE_FORGOT_PASSWORD_DRAWER,
  GET_RECHARGE_CHECKOUT,
  TOGGLE_RECHARGE_CART,
  RECHARGE_CART_VISIBILITY,
  UPDATE_RECHARGE_CHECKOUT,
} from "../../../graphql/queries"
import CartModal from "../../cart/CartModal"
import RechargeCartModal from "../../cart/RechargeCartModal"
import LoginModal from "../organisms/LoginModal"
import MobileHeaderMenu from "./MobileHeaderMenu"
import SignupModal from "../organisms/SignupModal"
import WebsiteContainer from "../atoms/WebsiteContainer"
import { getTotalCartCount } from "../../../services/cartService"
import ForgotPasswordModal from "../organisms/ForgotPasswordModal"

export default function Header({ navMenuItems }) {
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const { data } = useQuery(CART_VISIBILITY)
  const { data: rechargeCartVisibilityData } = useQuery(
    RECHARGE_CART_VISIBILITY
  )
  const [toggleCart] = useMutation(TOGGLE_CART)
  const [toggleRechargeCart] = useMutation(TOGGLE_RECHARGE_CART)
  const [updateRechargeCheckout] = useMutation(UPDATE_RECHARGE_CHECKOUT)
  const [toggleLoginDrawer] = useMutation(TOGGLE_LOGIN_DRAWER)
  const [toggleForgotPasswordDrawer] = useMutation(
    TOGGLE_FORGOT_PASSWORD_DRAWER
  )

  const [toggleRegisterDrawer] = useMutation(TOGGLE_REGISTER_DRAWER)
  const { data: loginDrawerVisibilityData } = useQuery(LOGIN_DRAWER_VISIBILITY)
  const { data: forgotPasswordDrawerVisibilityData } = useQuery(
    FORGOT_PASSWORD_DRAWER_VISIBILITY
  )
  const { data: registerDrawerVisibilityData } = useQuery(
    REGISTER_DRAWER_VISIBILITY
  )

  const { data: checkoutData, refetch, loading: checkoutLoading } = useQuery(
    CHECKOUT_BY_ID,
    {
      skip: !ls("lastCheckoutID"),
      variables: { id: ls("lastCheckoutID") },
      onCompleted: result => {},
    }
  )

  const {
    data: rechargeCheckoutData,
    refetch: refetchRechargeCart,
    loading: rechargeCheckoutLoading,
  } = useQuery(GET_RECHARGE_CHECKOUT, {
    skip: !ls("lastRechargeCheckoutID"),
  })

  const [fetchCheckout] = useLazyQuery(CHECKOUT_BY_ID)

  const { data: fetchCustomerData, loading: fetchCustomerLoading } = useQuery(
    FETCH_CUSTOMER,
    {
      skip: !ls("accessToken"),
      variables: { accessToken: ls("accessToken") },
      onCompleted: result => {
        if (get(result, "customer.lastIncompleteCheckout.id")) {
          ls(
            "lastCheckoutID",
            get(result, "customer.lastIncompleteCheckout.id")
          )

          fetchCheckout(get(result, "customer.lastIncompleteCheckout.id"))
        }
      },
    }
  )

  const customer = get(fetchCustomerData, "customer")

  useEffect(() => {
    if (
      performance.navigation.type == 2 &&
      performance.navigation.TYPE_RELOAD == 1
    ) {
     // ls("lastCheckoutID", ls('aftercheckoutCartID'))
     // ls("lastRechargeCheckoutID", ls('aftercheckoutRechargeCartID'))
      //  window.history.pushState("home", "Not Pot", "/");
      window.location.reload()
    }
    const url = window.location.href;
    var nav = false;
    var nav2 =false;
    var nav3 =false;
    //var cartUrl = false;
    ls("previousUrl", window.location.pathname);
    {url.includes("#Login") || url.includes("#login") ? nav=true : nav=false};
    {url.includes("#Signup") || url.includes("#signup")? nav2=true : nav2=false}
    {url.includes("#ForgotPassword") || url.includes("#forgotpassword")? nav3=true : nav3=false}
    //{url.includes("#Cart") || url.includes("#cart")? cartUrl=true : cartUrl=false}
    if(ls('ShowCart') == true){
      setTimeout(function(){
        toggleCart({
          variables: { cartDrawerVisible: true },
        }) 
       }, 100);   
       ls('ShowCart', false) 
    }
    if(nav){
      window.history.pushState("header", "Not Pot", "/#Login");
      setTimeout(function(){ 
        toggleLoginDrawer({
          variables: { loginDrawerVisible: true },
        }) 
      }, 100); 
    }
    if(nav2){
      window.history.pushState("header", "Not Pot", "/#Signup");
      setTimeout(function(){ 
        toggleRegisterDrawer({
          variables: { registerDrawerVisible: true },
        }) 
      }, 100); 
    }
    if(nav3){
      window.history.pushState("header", "Not Pot", "/#ForgotPassword");
      setTimeout(function(){ 
        toggleForgotPasswordDrawer({
          variables: { forgotPasswordDrawerVisible: true },
        }) 
      }, 100);
    }
    // if(cartUrl){
    //   window.history.pushState("header", "Not Pot", "/#Cart");
    //   setTimeout(function(){ 
    //     toggleCart({
    //       variables: { cartDrawerVisible: true },
    //     }) 
    //   }, 100);
    // }
  }, [])
  /**
   * on click profile button
   *
   * @param {*} e
   */
  const onClickProfileButton = e => {
    e.preventDefault()
    window.history.pushState("header", "Not Pot", ls("previousUrl"));
    if (customer) {
      navigate("/profile")

      return false
    }

    window.history.pushState("header", "Not Pot", "/#Login");
    toggleLoginDrawer({
      variables: { loginDrawerVisible: true },
    })
  }

  /**
   * on click forgot password btn
   */
  const onClickForgotPasswordBtn = () => {
     window.history.pushState("header", "Not Pot", ls("previousUrl"));
    toggleLoginDrawer({
      variables: { loginDrawerVisible: false },
    })

    toggleRegisterDrawer({
      variables: { registerDrawerVisible: false },
    })
    window.history.pushState("header", "Not Pot", "/#ForgotPassword");
    toggleForgotPasswordDrawer({
      variables: { forgotPasswordDrawerVisible: true },
    })
  }

  /**
   * on click sign up btn
   */
  const onClickSignUpBtn = () => {
    window.history.pushState("header", "Not Pot", ls("previousUrl"));
    toggleLoginDrawer({
      variables: { loginDrawerVisible: false },
    })
    window.history.pushState("header", "Not Pot", "/#Signup");
    toggleRegisterDrawer({
      variables: { registerDrawerVisible: true },
    })
  }

  /**
   * on click login btn
   */
  const onClickLoginBtn = () => {
    window.history.pushState("header", "Not Pot", ls("previousUrl"));
    window.history.pushState("header", "Not Pot", "/#Login");
    toggleLoginDrawer({
      variables: { loginDrawerVisible: true },
    })

    toggleRegisterDrawer({
      variables: { registerDrawerVisible: false },
    })
  }
  console.log(rechargeCheckoutData)
  console.log(checkoutData);
  return (
    <Div>
      <WebsiteContainer style={{padding:0}}>
        <Div d="flex" align="center" h="6.25rem">
          <CartModal
            customer={customer}
            guestCart={checkoutData?.node}
            isOpen={data?.cartDrawerVisible}
            rechargeCart={rechargeCheckoutData?.rechargeCheckout}
            updateRechargeCheckout={updateRechargeCheckout}
            toggleCart={toggleCart}
            onClose={() =>{
              /*window.history.pushState("header", "Not Pot", ls("previousUrl"))*/
              toggleCart({ variables: { cartDrawerVisible: false } })
            }
            }
          />
          <LoginModal
            isOpen={loginDrawerVisibilityData?.loginDrawerVisible}
            onClose={() =>{
              window.history.pushState("header", "Not Pot", ls("previousUrl"));
              toggleLoginDrawer({ variables: { loginDrawerVisible: false } })
            }}
            onClickForgotPasswordBtn={onClickForgotPasswordBtn}
            onClickSignUpBtn={onClickSignUpBtn}
          />
          <SignupModal
            isOpen={registerDrawerVisibilityData?.registerDrawerVisible}
            onClickLoginBtn={onClickLoginBtn}
            onClose={() =>{
              window.history.pushState("header", "Not Pot", ls("previousUrl"));
              toggleRegisterDrawer({
                variables: { registerDrawerVisible: false },
              })
            }}
          />
          <ForgotPasswordModal
            isOpen={
              forgotPasswordDrawerVisibilityData?.forgotPasswordDrawerVisible
            }
            onClose={() =>{
              window.history.pushState("header", "Not Pot", ls("previousUrl"));
              toggleForgotPasswordDrawer({
                variables: { forgotPasswordDrawerVisible: false },
              })
            }}
          />
          <Div
            // flexGrow="0 1 33.33%"
            // maxW="33.33%"
            h="6.25rem"
            w="100%"
            d="flex"
            transition
            // p="0 1.25rem"
            // m="0 -1.25rem"
            bg={{
              xs: showMobileMenu ? "pink" : "transparent",
              lg: "transparent",
            }}
            p={{ x: { xs: "1.25rem", md: "2.5rem", lg: "3rem", xl: "4.5rem" } }}
          >
            <Div flexGrow="1" d="flex" align="center">
              <Div d={{ xs: "none", lg: "flex" }}>
                {navMenuItems
                  .filter(link => link.type !== "secondary")
                  .map((link, id, index) => {
                    return (
                      <Div
                        m={{
                          r: index === navMenuItems.length - 1 ? "0" : "2rem",
                        }}
                        key={id}
                      >
                        <CTALink {...link} textWeight="bold" />
                      </Div>
                    )
                  })}
              </Div>
              {/* Header Left Mobile */}

              <Image
                onClick={() => setShowMobileMenu(!showMobileMenu)}
                src={
                  showMobileMenu ? "/menu-close.png" : "/mobile-menu-icon.png"
                }
                h="2.25rem"
                transition
                w="auto"
                d={{ xs: "block", lg: "none" }}
                shadow="7"
                rounded="lg"
              />

              <MobileHeaderMenu
                navMenuItems={navMenuItems}
                showMobileMenu={showMobileMenu}
                showLogin={onClickProfileButton}
                close={() => setShowMobileMenu(false)}
              />
            </Div>
            {/* Logo */}
            <Div flexGrow="1" d="flex" align="center" justify="center">
              {/* Mobile Logo */}
              <Link to="/">
                <Image
                  d={{ xs: "block", lg: "none" }}
                  src="https://cdn.shopify.com/s/files/1/2229/0673/files/Logo_-_Wordmark_-_Vertical.png?v=1595191555"
                  h="3.75rem"
                  w="auto"
                />
                {/* Desktop Logo */}
                <Image
                  d={{ xs: "none", lg: "block" }}
                  src="https://cdn.shopify.com/s/files/1/2229/0673/files/notpot-logo.png?v=1555703706"
                  h="1.25rem"
                  w="auto"
                />
              </Link>
            </Div>
            {/* Header Right */}
            <Div flexGrow="1" d="flex" align="center" justify="flex-end">
              <Div align="center" d={{ xs: "none", lg: "flex" }}>
                {/* <Image
                  src="/play-icon.svg"
                  h="1.5rem"
                  w="auto"
                  m={{ r: "1.875rem" }}
                  rounded="100%"
                  shadow="6"
                  cursor="pointer"
                /> */}
                <Anchor href="" h="1.25rem" onClick={onClickProfileButton}>
                  <Image
                    src="/profile-icon.png"
                    h="1.25rem"
                    w="auto"
                    m={{ r: "1.875rem" }}
                  />
                </Anchor>
                <Div m={{ r: "1.875rem" }}>
                  {[1, 2, 3].map((index, id) => {
                    return (
                      <Div
                        key={id}
                        h="5px"
                        w="5px"
                        bg="purple"
                        shadow="3"
                        rounded="circle"
                        m={{ y: index === 2 ? "5px" : "0" }}
                      />
                    )
                  })}
                </Div>
              </Div>
              <Div
                d="flex"
                flexGrow={{ xs: "1", lg: "initial" }}
                justify="flex-end"
              >
                <Div
                  bg="pink"
                  rounded="circle"
                  p="10px 20px"
                  d="flex"
                  shadow="2"
                  hoverShadow="3"
                  transition
                  cursor="pointer"
                  m="auto 0"
                  onClick={() => {
                    /*window.history.pushState("header", "Not Pot", "/#Cart")*/
                    setShowMobileMenu(false);
                    toggleCart({
                      variables: { cartDrawerVisible: true },
                    })
                  }}
                >
                  <Image src="/disk.svg" h="1rem" m={{ r: "5px" }} />
                  <Text
                    fontFamily="secondary"
                    textWeight="bold"
                    style={{ lineHeight: "14px" }}
                  >
                    {getTotalCartCount(
                      checkoutData?.node,
                      rechargeCheckoutData,
                      checkoutLoading || rechargeCheckoutLoading
                    )}
                  </Text>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </WebsiteContainer>
    </Div>
  )
}
